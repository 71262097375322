import { useState } from 'react';
import { createUseStyles } from 'react-jss';

import { ReactComponent as DOWN } from '../assets/images/down.svg';

export default function Selector2({
  data,
  onChange,
  value,
  alternateValue,
  label,
  border = true,
}) {
  const useStyles = createUseStyles({
    selector: {
      justifyContent: 'center',
      display: 'flex',
      position: 'relative',
      width: '100%',
    },
    placeholder: {
      display: 'flex',
      border: border ? '1px solid #ebebeb' : 'none',
      borderRadius: '5px',
      width: '100%',
      cursor: 'pointer',
      alignItems: 'center',
      '& .icon': {
        marginRight: '10px',
        minWidth: '30px',
        maxWidth: '30px',
        maxHeight: '30px',
        borderRadius: '8px',
        overflow: 'hidden',
        border: '1px solid #ebebeb',
        '& svg': {
          width: '100%',
          height: '100%',
        },
      },
      '& .text': {
        width: '100%',
        fontWeight: '600',
        '& .label': {
          color: '#999999',
          fontSize: '10px',
          fontWeight: 'bold',
        },
      },
      '& .down': {
        '& svg': {
          minWidth: '16px',
          width: '16px',
          height: '16px',
        },
      },
    },
    options: {
      zIndex: 9999,
      position: 'absolute',
      top: 'calc(100% + 10px)',
      padding: '8px',
      left: '0',
      backgroundColor: '#ffffff',
      boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.05)',
      borderRadius: '10px',
      overflow: 'hidden',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    option: {
      backgroundColor: '#ffffff',
      padding: '8px',
      fontSize: '14px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#f2f2f2',
      },
      '& .icon': {
        marginRight: '5px',
        width: '20px',
        height: '20px',
        borderRadius: '5px',
        overflow: 'hidden',
        border: '1px solid #ebebeb',
        '& svg': {
          width: '100%',
          height: '100%',
        },
      },
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  });

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <div
      className={classes.selector}
      tabIndex={0}
      onBlur={() => setOpen(false)}
    >
      <div className={classes.placeholder} onClick={() => setOpen(!open)}>
        {data && data.length > 0 && value && (
          <>
            {data.find((item) => item.value === value).icon && (
              <div className="icon">
                {data.find((item) => item.value === value).icon}
              </div>
            )}
            <div className="text">
              {label && <div className="label">{label}</div>}
              {data.find((item) => item.value === value).label}
            </div>
            <div className="down">
              <DOWN />
            </div>
          </>
        )}
      </div>
      {data && data.length > 0 && open ? (
        <>
          <div className={classes.options}>
            {data.map((item, index) => {
              if (item.value === value || item.value === alternateValue) {
                return <></>;
              }

              return (
                <div
                  key={index}
                  className={classes.option}
                  onClick={() => {
                    onChange(item.value);
                    setOpen(false);
                  }}
                >
                  {item.icon && <div className="icon">{item.icon}</div>}
                  {item.label}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
