import { createUseStyles } from 'react-jss';
import Selector2 from './Selector2';

import { ReactComponent as RIGHTARROW } from '../assets/images/right-arrows.svg';

function findCurrencyByCode({ code, currencyList }) {
  return currencyList && currencyList.length > 0
    ? currencyList.find((c) => c.value === code)?.label
    : undefined;
}

function format_number(number, digits = 6) {
  console.log(number);

  // if the number is not a pure number, remove all the non-digit characters except dot and convert it to number
  number = parseFloat(number.toString().replace(/[^\d.]/g, ''));
  // if the number is not a number, return 0
  if (isNaN(number)) {
    return '0';
  }

  // if the number is lower than 1, return the number with 6 digits, otherwise return the number with 3 digits
  if (number < 1) {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: digits,
    }).format(number);
  } else {
    return new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 3,
    }).format(number);
  }
}

function format_to_raw_number(number) {
  return parseFloat(number.toString().replace(/[^\d.]/g, ''));
}

function format_price(amount, currency, digits = 0) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: digits,
  }).format(amount);
}

const useStyles = createUseStyles({
  CurrencyWrapper: {
    padding: '20px',
    borderRadius: '20px',
    border: '1px solid #ebebeb',
    backgroundColor: '#fff',
  },
  ControlPart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ebebeb',
    paddingBottom: '20px',
    marginBottom: '10px',
  },
  Selector: {
    width: '100px',
  },
  Input: {
    '& .input': {
      width: '100%',
      textAlign: 'right',
      border: '0',
      fontSize: '24px',
      lineHeight: '1',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  result: {
    fontSize: '14px',
    color: '#666666',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    '& svg': {
      width: '16px',
      height: '16px',
      fill: '#666666',
      marginLeft: '10px',
      marginRight: '10px',
    },
  },
});

export default function Currency({
  currencyList,
  currencyFrom,
  currencyTo,
  amountFrom,
  amountTo,
  onChangeCurrency,
  onChangeAmount,
}) {
  const classes = useStyles();

  if (!currencyFrom || !currencyList) {
    return <></>;
  }

  const codeFrom = findCurrencyByCode({ code: currencyFrom, currencyList });
  const codeTo = findCurrencyByCode({ code: currencyTo, currencyList });

  return (
    <div className={classes.CurrencyWrapper}>
      <div className={classes.ControlPart}>
        <div className={classes.Selector}>
          <Selector2
            data={currencyList}
            value={currencyFrom}
            alternateValue={currencyTo}
            onChange={onChangeCurrency}
            border={false}
          />
        </div>
        <div className={classes.Input}>
          <input
            className="input"
            type="text"
            value={format_number(amountFrom)}
            onChange={onChangeAmount}
          />
        </div>
      </div>
      <div className={classes.result}>
        1 {codeFrom} <RIGHTARROW /> {format_number(amountTo / amountFrom)}{' '}
        {codeTo}
      </div>
    </div>
  );
}
