import axios from 'axios';
import './App.css';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Toaster } from 'react-hot-toast';

// flat svg import
import { ReactComponent as HKD } from './assets/images/hkd.svg';
import { ReactComponent as TWD } from './assets/images/twd.svg';
import { ReactComponent as KRW } from './assets/images/krw.svg';
import { ReactComponent as JPY } from './assets/images/jpy.svg';
import { ReactComponent as IDR } from './assets/images/idr.svg';
import { ReactComponent as MYR } from './assets/images/myr.svg';
import { ReactComponent as SGD } from './assets/images/sgd.svg';
import { ReactComponent as THB } from './assets/images/thb.svg';
import { ReactComponent as MOP } from './assets/images/mop.svg';
import { ReactComponent as PHP } from './assets/images/php.svg';
import { ReactComponent as VND } from './assets/images/vnd.svg';

import { ReactComponent as CHANGE } from './assets/images/exchange.svg';
import { ReactComponent as RESET } from './assets/images/reset.svg';

import Currency from './components/Currency';

// if the env is local, set debug to true
const debug = process.env.NODE_ENV === 'development';

const icons = {
  HKD: <HKD />,
  TWD: <TWD />,
  KRW: <KRW />,
  JPY: <JPY />,
  IDR: <IDR />,
  MYR: <MYR />,
  SGD: <SGD />,
  THB: <THB />,
  MOP: <MOP />,
  PHP: <PHP />,
  VND: <VND />,
};

const borderRadius = '8px';

const useStyles = createUseStyles({
  cashmallow_calculator_app: {
    width: '100%',
    position: 'relative',
    zIndex: 10,
    padding: debug ? '20px' : '0',
    maxWidth: debug ? '400px' : '100%',
    backgroundColor: debug ? '#f0f0f0' : 'transparent',
  },
  calculator_wrapper: {},
  calculator_header: {
    marginBottom: '20px',
  },
  currency_label: {
    marginBottom: '5px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    color: '#666666',
  },
  currency_connector: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#999',
    '& svg': {
      '&:hover': {
        color: '#000000',
        '& path': {
          fill: '#000000',
        },
      },
      '& path': {
        fill: '#999',
      },
      padding: '5px',
      width: '40px',
      height: '40px',
    },
  },
  currency_1: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ebebeb',
    borderRadius: borderRadius,
  },
  currency_2: {
    width: 'calc(50% - 20px)',
  },
  calculator_body: {},
  currency_1_amount: {
    flex: '1',
  },
  currency_1_amount_input: {
    width: '100%',
    padding: '10px 15px',
    border: '0',
    borderRadius: borderRadius,
    fontSize: '24px',
    lineHeight: '1',
    '&:focus': {
      outline: 'none',
    },
  },
  currency_1_selector: {},
  calculator_footer: {
    marginTop: '20px',
  },
  result_label: {
    marginBottom: '5px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  result_amount: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  result_average: {
    fontSize: '12px',
    color: '#666666',
    marginTop: '5px',
  },
  spacer: {
    height: '10px',
  },
  reset: {
    border: '0',
    fontSize: '14px',
    cursor: 'pointer',
    width: '100%',
    fontWeight: 'bold',
    color: '#999999',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      marginRight: '5px',
      width: '20px',
      height: '20px',
      fill: '#999999',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#000000',
      '& svg': {
        fill: '#000000',
      },
    },
  },
});

function format_to_raw_number(number) {
  return parseFloat(number.toString().replace(/[^\d.]/g, ''));
}

function App() {
  const classes = useStyles();

  // const [amount, setAmount] = useState(0);
  const [currencyList, setCurrencyList] = useState([]);

  const [currency_1_amount, setCurrency_1_amount] = useState(1000);
  const [currency_2_amount, setCurrency_2_amount] = useState(0);
  const [currency_1, setCurrency_1] = useState('001'); // HKD
  const [currency_2, setCurrency_2] = useState('003'); // KRW

  const apiBase = 'https://tiger.cashmallow.com/api';
  const key = 'cabd899a-c010-4aca-b6f0-966ddb8ff2c9';

  function call({ method = 'get', url, data }) {
    return axios(url, {
      method: method,
      data: data,
      mode: 'no-cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      // credentials: "same-origin",
      // withCredentials: true,
    })
      .then((response) => {
        return response.data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handle_currency_1_amount_change(e) {
    const value = e.target.value;

    if (value === '') {
      setCurrency_1_amount('');
      setCurrency_2_amount(0);
      return;
    }

    // value checking, only allow numbers, dot, comma
    if (value && value.match(/[^0-9.,]/)) {
      return;
    }

    setCurrency_1_amount(format_to_raw_number(value));

    getRateFromCurrency1ToCurrency2(format_to_raw_number(value));
  }

  function handle_currency_2_amount_change(e) {
    const value = e.target.value;

    if (value === '') {
      setCurrency_2_amount('');
      setCurrency_1_amount(0);
      return;
    }

    // value checking, only allow numbers, dot, comma
    if (value && value.match(/[^0-9.,]/)) {
      return;
    }

    setCurrency_2_amount(format_to_raw_number(value));

    getRateFromCurrency2ToCurrency1(format_to_raw_number(value));
  }

  function handle_currency_1_change(value) {
    setCurrency_1(value);
  }

  function handle_currency_2_change(value) {
    setCurrency_2(value);
  }

  function handleExchangeCurrency() {
    const temp = currency_1;
    setCurrency_1(currency_2);
    setCurrency_2(temp);
  }

  function handleReset() {
    setCurrency_1('001');
    setCurrency_2('003');
    setCurrency_1_amount(1000);
  }

  async function getRateFromCurrency1ToCurrency2(value) {
    const link = `${apiBase}/homepage/exchange/calculate?key=${key}&from_cd=${currency_1}&to_cd=${currency_2}&from_money=${
      value || currency_1_amount
    }`;
    const rateData = await call({ method: 'get', url: link });

    // const rateData = exchangeRateData.data;

    setCurrency_2_amount(rateData.toMoney);
  }

  async function getRateFromCurrency2ToCurrency1(value) {
    const link = `${apiBase}/homepage/exchange/calculate?key=${key}&from_cd=${currency_2}&to_cd=${currency_1}&from_money=${
      value || currency_2_amount
    }`;
    const rateData = await call({ method: 'get', url: link });

    // const rateData = exchangeRateData.data;

    setCurrency_1_amount(rateData.toMoney);
  }

  useEffect(() => {
    async function getCurrencyList() {
      const link = `${apiBase}/homepage/exchanges?key=${key}`;
      let listData = await call({ method: 'get', url: link });

      // let listData = currencyListData.data;

      // restructuring the data
      listData = listData.map((currency) => {
        return {
          label: currency.iso4217,
          value: currency.code,
          icon: icons[currency.iso4217],
        };
      });

      setCurrencyList(listData);
    }

    getCurrencyList();
  }, []);

  useEffect(() => {
    if (
      currency_1 &&
      currency_2 &&
      currency_1_amount &&
      currency_1_amount > 0
    ) {
      getRateFromCurrency1ToCurrency2();
    }
  }, [currency_1, currency_2]);

  return (
    <>
      <div className={classes.cashmallow_calculator_app}>
        <Currency
          icons={icons}
          currencyList={currencyList}
          currencyFrom={currency_1}
          currencyTo={currency_2}
          amountFrom={currency_1_amount}
          amountTo={currency_2_amount}
          onChangeCurrency={handle_currency_1_change}
          onChangeAmount={handle_currency_1_amount_change}
        />

        <div className={classes.spacer}></div>

        <div
          className={classes.currency_connector}
          onClick={handleExchangeCurrency}
        >
          <CHANGE />
        </div>

        <div className={classes.spacer}></div>

        <Currency
          icons={icons}
          currencyList={currencyList}
          currencyFrom={currency_2}
          currencyTo={currency_1}
          amountFrom={currency_2_amount}
          amountTo={currency_1_amount}
          onChangeCurrency={handle_currency_2_change}
          onChangeAmount={handle_currency_2_amount_change}
        />

        <div className={classes.spacer}></div>

        <div className={classes.calculator_end}>
          <div className={classes.reset} onClick={handleReset}>
            <RESET />
          </div>
        </div>
      </div>

      <Toaster />
    </>
  );
}

export default App;
